.App {
  text-align: center;
  /* position: relative; */
}

.playlist {
  margin: 50px auto;
  text-align: center;
}
th {
  width: 200px;
}
